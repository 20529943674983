import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Dar} from "../models/dar.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {SituacaoDar} from "../models/situacao-dar.model";


@Injectable({
    providedIn: 'root'
})
export class DarService extends BaseService<Dar> {
    constructor(protected injector: Injector) {
        super('/api/dar', injector, Dar.prototype, Dar.fromJson);
    }

    buscarReaproveitamentoPorDar(numeroDAR: string): Observable<Dar> {
        const url = `${this.urlResource}/reaproveitamento/${numeroDAR}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    atualizarNumProcessoDar(numDar: number, numProcesso: string): Observable<any> {
        const url = `${this.urlResource}/vincular-processo/${numDar}`;
        return this.http.get(url, {
            params: {numProcesso: numProcesso}
        }).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarTodasAsSituacoesDar(): Observable<SituacaoDar[]> {
        const url = `${this.urlResource}/listar-situacoes-dar`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarDarsEmitidos(search: any): Observable<Dar[]> {
        const url = `${this.urlResource}/buscar-dars-emitidos`;
        return this.http.post(url, search).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    obterDemonstrativoTaxa(numDar: number): Observable<any> {
        const url = `${this.urlResource}/demonstrativo-pagamento/${numDar}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map(res => res),
            catchError(this.handleError.bind(this))
        );
    }

    obterRelatorioPosicaoCliente(search: any): Observable<any> {
        const url = `${this.urlResource}/relatorio-posicao-cliente`;
        return this.http.post(url, search, {responseType: 'blob'}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}
