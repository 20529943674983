import {BaseModel} from "./base.model";
import {Pessoa} from "../../main/core/components/models/pessoa.model";

export class PessoaJuridica extends BaseModel {

    constructor(
        public id?: number,
        public cnpj?: string,
        public razaoSocial?: string,
        public nomeFantasia?: string,
        public inscricaoMunicipal?: string,
        public inscricaoEstadual?: string,
        public naturezaJuridica?: string,
        public porteEmpresa?: string,
        public dataConstituicao?: Date,
        public dataInicio?: Date,
        public dataAtualizacao?: string,
        public migrado?: boolean,
        public pessoa?: Pessoa,
    ) {
        super();
    }

    static fromJson(json: any): PessoaJuridica {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaJuridica(
            json.id,
            json.cnpj,
            json.razaoSocial,
            json.nomeFantasia,
            json.inscricaoMunicipal,
            json.inscricaoEstadual,
            json.naturezaJuridica,
            json.porteEmpresa,
            json.dataConstituicao,
            json.dataInicio,
            json.dataAtualizacao,
            json.migrado,
            Pessoa.fromJson(json.pessoa),
        );
    }
}
