import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {LancamentosCreditosDisponiveisRetornoDto} from "../models/lancamentos-creditos-disponiveis-retornoDto.model";
import {ExtratoFinanceiro} from "../models/extrato-financeiro.model";
import {ContaCorrente} from "../models/conta-corrente.model";
import {LancamentoReceitaNaturezaDto} from "../models/lancamento-receita-natureza-dto.model";

@Injectable({
    providedIn: 'root'
})
export class ContaCorrenteService extends BaseService<LancamentosCreditosDisponiveisRetornoDto> {
    constructor(protected injector: Injector) {
        super('/api/conta-corrente', injector, ExtratoFinanceiro.prototype, ExtratoFinanceiro.fromJson);
    }

    buscarExtratoFinanceiro(idPessoa: number): Observable<ExtratoFinanceiro> {
        return this.http
            .get(`${this.urlResource}/extrato-financeiro/${idPessoa}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarSaldoUpfPorIdPessoa(idPessoa: number): Observable<any> {
        return this.http
            .get(`${this.urlResource}/saldo-upf-pessoa/${idPessoa}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }


    buscarContaCorrente(idPessoa: number): Observable<ContaCorrente> {
        const url = `${this.urlResource}/buscar-conta/${idPessoa}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarSaldoContaCorrente(idContaCorrente: number): Observable<number> {
        const url = `${this.urlResource}/saldo-moeda/${idContaCorrente}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        )
    }

    buscarSaldoUpfContaCorrente(idContaCorrente: number): Observable<number> {
        const url = `${this.urlResource}/saldo-upf/${idContaCorrente}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        )
    }

    buscarCreditosDisponiveis(idPessoa: number): Observable<LancamentoReceitaNaturezaDto[]> {
        return this.http
            .get(`${this.urlResource}/creditos-disponiveis/${idPessoa}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }
}
