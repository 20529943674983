import { Injectable } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {DialogConfirmacaoSubGrupoAtividadesComponent} from "./dialog-confirmacao-sub-grupo-atividades.component";

@Injectable({
  providedIn: 'root'
})
export class DialogConfirmacaoSubGrupoAtividadeService {

  constructor( private dialog: MatDialog ) { }

    openConfimDialog(acao: string, tipo: string, nome: string ){
        return this.dialog.open(DialogConfirmacaoSubGrupoAtividadesComponent, {
            width: '390px',
            panelClass: 'confirm-dialog-container',
            disableClose: true,
            data: {
                message: 'Tem certeza que deseja ' + acao + ' o(a) ' + tipo + ' ' + '"' + nome + '"' + '?'
            }
        })
    }
}
