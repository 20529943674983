import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {TipoTitulacao} from "../models/tipo-titulacao.model";

@Injectable({
    providedIn: 'root'
})
export class TipoTitulacaoService extends BaseService<TipoTitulacao> {
    constructor(protected injector: Injector) {
        super('/api/tipo-titulacao', injector, TipoTitulacao.prototype, TipoTitulacao.fromJson);
    }

    buscarListaTipoTitulacao(): Observable<TipoTitulacao[]> {
        const url = `${this.urlResource}/listar-titulacao`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getTipoTitulacaoPorDescricao(descricao: string): Observable<TipoTitulacao[]> {
        const url = `${this.urlResource}/lista-tipo-titulacao/${descricao}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

}
