import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {CentroCusto} from "../models/centro-custo.model";

@Injectable({
    providedIn: 'root'
})
export class CentroCustoService extends BaseService<CentroCusto> {
    constructor(protected injector: Injector) {
        super('/api/centro-custo', injector, CentroCusto.prototype, CentroCusto.fromJson);
    }

    buscarListaCentroCusto(): Observable<CentroCusto[]> {
        const url = `${this.urlResource}/listar-centro-custo`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getByCentroCustoDesc(descricao: string): Observable<CentroCusto[]> {
        return this.http
            .get(`${this.urlResource}/descricao/${descricao}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

}
