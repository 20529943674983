import {BaseModel} from "./base.model";
import {LancamentoReceitaNaturezaDto} from "./lancamento-receita-natureza-dto.model";

export class ExtratoFinanceiro extends BaseModel {

    constructor(
        public lancamentosDisponiveis: LancamentoReceitaNaturezaDto[]
    ) {
        super();
    }

    static fromJson(json: any): ExtratoFinanceiro {
        if (json === undefined || json === null) {
            return null;
        }
        return new ExtratoFinanceiro(
            json.lancamentosDisponiveis,
        );
    }

    static fromJsons(jsonArray: any): ExtratoFinanceiro[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}