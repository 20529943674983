import {BaseModel} from "./base.model";
import {EnumUf} from "./enum/uf.enum";

export class Municipio extends BaseModel {
    constructor(
        public nome?: string,
        public uf?: EnumUf,
        public codigoIbge?: number,
    ) {
        super();
    }

    static fromJson(json: any): Municipio {
        if (json === undefined || json === null) {
            return null;
        }
        return new Municipio(
            json.nome,
            json.uf,
            json.codigoIbge
        );
    }
}
