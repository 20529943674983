import {BaseModel} from "./base.model";
import {PessoaFisica} from "./pessoa-fisica.model";
import {PessoaJuridica} from "./pessoa-juridica.model";

export class RessarcimentoTaxa extends BaseModel{
    constructor(
        public id?: number,
        public idPessoa?: number,
        public pessoaFisica?: PessoaFisica,
        public pessoaJuridica?: PessoaJuridica,
        public dataSolicitacao?: Date,
        public banco?: string,
        public agencia?: string,
        public conta?: string,
        public tipoContaBancaria?: string,
        public parecerSolicitacao?: string,
        public parecerCpfEmissor?: string,
    ) {
        super();
    }

    static fromJson(json: any): RessarcimentoTaxa {
        if (json === undefined || json === null) {
            return null;
        }
        return new RessarcimentoTaxa(
            json.id,
            json.idPessoa,
            PessoaFisica.fromJson(json.PessoaFisica),
            PessoaJuridica.fromJson(json.PessoaJuridica),
            json.dataSolicitacao,
            json.banco,
            json.agencia,
            json.conta,
            json.tipoContaBancaria,
            json.parecerSolicitacao,
            json.parecerCpfEmissor,
        );
    }
}