import {Injectable, Injector} from '@angular/core';
import {Municipio} from '../models/municipio.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from "./base.service";
import {environment} from "../../../environments/environment";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root',
})
export class MunicipioService extends BaseService<Municipio> {

    private urlResourceGeoBaseReferencia = environment.URL_GEOPORTAL_BASE_REFERENCIA

    constructor(protected injector: Injector) {
        super(
            '/api/municipios',
            injector,
            Municipio.prototype,
            Municipio.fromJson
        );
    }

    getAllByUf(uf: any): Observable<Municipio[]> {
        let params: HttpParams = new HttpParams();
        params = params.set('uf', uf.toUpperCase());
        const url = `${this.urlResourceGeoBaseReferencia}/api/municipios`;
        return this.http.get(url, {params: params}).pipe(
            map((response) => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }
}
