import {BaseModel} from "./base.model";

export class ModuloFiscal extends BaseModel {

    constructor(
        public id?: number,
        public municipio?: string,
        public codIbge?: number,
        public moduloFiscal?: number
    ) {
        super();
    }

    static fromJson(json: any): ModuloFiscal {
        if (json === undefined || json === null) {
            return null;
        }
        return new ModuloFiscal(
            json.id,
            json.municipio,
            json.codIbge,
            json.moduloFiscal
        );
    }
}
