import {Injectable, Injector} from "@angular/core";
import {BaseService} from "./base.service";
import {EmissaoTaxa} from "../models/emissao-taxa.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Dar} from "../models/dar.model";
import {TipoLogradouro} from "../models/tipo-logradouro.model";
import {EnderecoPessoaDto} from "../models/endereco-pessoa-dto.model";

@Injectable({
    providedIn: 'root'
})

export class EmissaoTaxaService extends BaseService<EmissaoTaxa> {
    constructor(protected injector: Injector) {
        super('/api/emissao-taxa', injector, EmissaoTaxa.prototype, EmissaoTaxa.fromJson);
    }

    salvar(resource: EmissaoTaxa): Observable<Dar[]> {
        return this.http
            .post(`${this.urlResource}/salvar`, resource)
            .pipe(
                map(res => res),
                catchError(this.handleError.bind(this))
            );
    }

    downloadDocumentos(resource: Dar[]): Observable<Blob> {
        return this.http
            .post(`${this.urlResource}/download-documentos`, resource, {
                responseType: 'blob'
            }).pipe(map((res) => {
                return new Blob([res], {type: res.type});
            }));
    }

    retornarListaDeLogradouro(): Observable<TipoLogradouro[]> {
        const url = `${this.urlResource}/buscar-lista-logradouro`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    salvarEnderecoContatoInexistente(enderecoPessoaDto: any): Observable<EnderecoPessoaDto> {
        return this.http
            .post(`${this.urlResource}/adicionar-endereco-contato-incompleto`, enderecoPessoaDto)
            .pipe(
                map(res => res),
                catchError(this.handleError.bind(this))
            );
    }

    buscarDadosContatoPessoa(idPessoa: number) {
        const url = `${this.urlResource}/dados-contato-pessoa/${idPessoa}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }
}
