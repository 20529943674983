import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {ReceitaGrupo} from "../models/receita-grupo.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ReceitaGrupoService extends BaseService<ReceitaGrupo> {
    constructor(protected injector: Injector) {
        super('/api/receita-grupo', injector, ReceitaGrupo.prototype, ReceitaGrupo.fromJson);
    }

    getReceitaGrupoPorDescricao(descricao: string): Observable<ReceitaGrupo[]> {
        const url = `${this.urlResource}/lista-receita-grupo/${descricao}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );

    }
}
