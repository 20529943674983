import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {ReceitaNatureza} from "../models/receita-natureza.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ReceitaNaturezaService extends BaseService<ReceitaNatureza> {
    constructor(protected injector: Injector) {
        super('/api/receita-natureza', injector, ReceitaNatureza.prototype, ReceitaNatureza.fromJson);
    }

    buscarListaReceitaNaturezaPorId(id: number): Observable<ReceitaNatureza> {
        const url = `${this.urlResource}/id/${id}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarListaReceitaNatureza(): Observable<ReceitaNatureza[]> {
        const url = `${this.urlResource}/listar-receita-natureza`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getReceitaPorDescricao(descricao: string): Observable<ReceitaNatureza[]> {
        const url = `${this.urlResource}/lista-natureza/${descricao}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );

    }
}
