import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {ModuloFiscal} from "../models/modulo-fiscal.model";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ModuloFiscalService extends BaseService<ModuloFiscal> {
    constructor(protected injector: Injector) {
        super('/api/modulo-fiscal', injector, ModuloFiscal.prototype, ModuloFiscal.fromJson);
    }

    buscarTodosModulosFiscais(): Observable<any> {
        const url = `${this.urlResource}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarModulosFiscais(httpParams: HttpParams): Observable<any> {
        const url = `${this.urlResource}/listar-modulos-fiscais`;
        return this.http
            .get(url, {params: httpParams})
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

}
