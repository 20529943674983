import {Injectable, Injector} from "@angular/core";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {RessarcimentoTaxa} from "../models/ressarcimento-taxa.model";
import {RessarcimentoTaxaDocumento} from "../models/ressarcimento-taxa-documento.model";
import {RessarcimentoTaxaDarDto} from "../models/ressarcimento-taxa-dar-dto.model";
import {RessarcimentoReceitaNaturezaDto} from "../models/ressarcimento-receita-natureza-dto.model";

@Injectable({
    providedIn: 'root'
})

export class RessarcimentoTaxaService extends BaseService<RessarcimentoTaxa> {
    constructor(protected injector: Injector) {
        super('/api/ressarcimento-taxa', injector, RessarcimentoTaxa.prototype, RessarcimentoTaxa.fromJson);
    }

    criarRessarcimentoTaxa(ressarcimentoTaxaDto: RessarcimentoTaxaDarDto): Observable<RessarcimentoTaxa> {
        return this.http.post(this.urlResource, ressarcimentoTaxaDto).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    adicionarDocumentos(formData: FormData, idRessarcimentoTaxa: number): Observable<RessarcimentoTaxaDocumento> {
        const url = `${this.urlResource}/documentos/${idRessarcimentoTaxa}`;
        return this.http.post(url, formData).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarRessarcimentoTaxaDarPorIdRessarcimento(idRessarcimento: number): Observable<RessarcimentoReceitaNaturezaDto[]> {
        const url = `${this.urlResource}/analisar-ressarcimento/${idRessarcimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarDocumentosRessarcimento(idRessarcimento: number): Observable<RessarcimentoTaxaDocumento[]> {
        const url = `${this.urlResource}/buscar-documentos/${idRessarcimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarRessarcimento(idRessarcimento: number): Observable<RessarcimentoTaxa> {
        const url = `${this.urlResource}/${idRessarcimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    atualizarRessarcimento(ressarcimentoTaxa: RessarcimentoTaxa, acao: string, taskId: string): Observable<RessarcimentoTaxa> {
        const url = `${this.urlResource}/${ressarcimentoTaxa.id}/${acao}/${taskId}`;
        return this.http.put(url, ressarcimentoTaxa).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    public recuperarPdfSalvo(chave: string): Observable<Blob> {
        const url = `${this.urlResource}/${chave}/recuperar-pdf`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    public recuperarDocumentoAutoriazacao(idRessarcimento: number): Observable<Blob> {
        const url = `${this.urlResource}/documento-gerado-apos-aprovacao/${idRessarcimento}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: res.type});
                }
            ));
    }

    buscarRessarcimentosPorCpfCpnj(cpfCnpj: string): Observable<RessarcimentoReceitaNaturezaDto[]>{
        const url = `${this.urlResource}/buscar-ressarcimentos-por-cpf-cnpj/${cpfCnpj}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}